import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/facilities.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { StaticImage } from "gatsby-plugin-image"

export default function TriumphPage(props) {
  return (
    <Layout>
      <Seo title="Triumph in the West" />
      <div className="contentBox">
        <div className="mobile-back-bttn">
          <AniLink cover to="/facilities">
            Back to Facilities
          </AniLink>
        </div>
        <h1 className="facility-title">Triumph in the West</h1>
        <div className="facility-container">
          <div className="facility-front-picture-container">
            <StaticImage
              src="../images/facilities/triumph/triumph-front3.jpg"
              alt="frontyard"
              placeholder="blurred"
            />
          </div>
          <div className="facility-details-container">
            <div className="facility-detail-row">
              <p className="detail-title">EST</p>
              <p className="detail">2019</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">License #</p>
              <p className="detail">157208935</p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Description</p>
              <p className="detail">
                4 bed, Specialized (Delayed Egress/Secure Perimeter,Enhanced
                Behavioral Supports Home (EBSH), Adult Residential Facility (18
                to 59 years of age). TIW is Located in a rural community in
                North West Bakersfield.
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Services</p>
              <p className="detail">
                Delayed Egress, Secure Perimeter, 24 hour supervision, Private
                rooms, Window/door chimes, Security Cameras, Social skills
                training, Enhanced staffing/experienced staff, Crisis Prevention
                Intervention, Predominantly male staff, Supervision by a
                Licensed Psychiatric Technician/Registered Behavior Technician
              </p>
            </div>
            <div className="facility-detail-row">
              <p className="detail-title">Specialization</p>
              <p className="detail">
                Individuals currently residing at, or who are at risk of
                entering, a developmental center. Individuals may have a mild to
                moderate intellectual disability and a co-occurring mental
                health diagnosis.
              </p>
            </div>
          </div>
        </div>

        <h2 className="pictures-title">Facility Pictures</h2>
        <div className="masonry">
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-back1.jpg"
              alt="backyard"
              placeholder="blurred"
              width={600}
              height={600}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-back3.jpg"
              alt="backyard"
              placeholder="blurred"
              width={400}
              height={500}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-kitchen1.jpg"
              alt="kitchen"
              placeholder="blurred"
              width={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-living2.jpg"
              alt="living area"
              placeholder="blurred"
              width={500}
              height={500}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-kitchen2.jpg"
              alt="kitchen"
              placeholder="blurred"
              width={400}
              height={400}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-kitchen3.jpg"
              alt="kitchen"
              placeholder="blurred"
              width={500}
              height={500}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-living3.jpg"
              alt="living area"
              placeholder="blurred"
              width={400}
              height={600}
            />
          </div>
          <div className="item">
            <StaticImage
              src="../images/facilities/triumph/triumph-living1.jpg"
              alt="living area"
              placeholder="blurred"
              width={500}
              height={500}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}
